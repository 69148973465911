import React from "react";
import ContentElement from "../Question/ContentElement";
import { RESPONSE_STATUS_COMPLETED, TABS } from "../../utils/constants";

import "./Page.css";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedSections: new Set()
    };
  }

  isDisableField = () => {
    const {
      navigationType,
      responseBySessionAndStatus,
      isEditEnabled
    } = this.props;
    if (
      navigationType === TABS &&
      responseBySessionAndStatus &&
      responseBySessionAndStatus.sessionId &&
      responseBySessionAndStatus.status === RESPONSE_STATUS_COMPLETED
    ) {
      if (isEditEnabled) {
        return false;
      }
      return true;
    }
    return false;
  };

  updateState = newValue => {
    this.setState({ expandedSections: newValue });
  };

  render() {
    const {
      children,
      content,
      session,
      onAddressChanged,
      validator,
      onUpdating,
      survey,
      startHTML,
      endHTML
    } = this.props;

    if (validator) {
      validator.purgeFields();
    }

    // Process the flat config to determine section boundaries
    function processedConfig(formConfig) {
      let currentTextHeader = null;
      let draggableListIndexMap = {};

      return formConfig.reduce((acc, element, index) => {
        // Update section tracking
        if (element.type === "text") {
          currentTextHeader = element;
        }

        // Find the next relevant header based on current element type
        let nextHeaderIndex = formConfig.length;
        if (element.type === "text") {
          // Find next text header
          for (let i = index + 1; i < formConfig.length; i++) {
            if (formConfig[i].type === "text") {
              nextHeaderIndex = i;
              break;
            }
          }
        } else if (element.type === "sub_text") {
          // Find next sub_text header or text header
          for (let i = index + 1; i < formConfig.length; i++) {
            if (
              formConfig[i].type === "sub_text" ||
              formConfig[i].type === "text"
            ) {
              nextHeaderIndex = i;
              break;
            }
          }
        } else if (element.type === "draggableList") {
          draggableListIndexMap = {
            ...draggableListIndexMap,
            [element._id]: index
          };
        }

        const updatedContent = {
          ...element,
          parentTextId: currentTextHeader?._id,
          sectionEnd: nextHeaderIndex
        };

        if (
          element.type !== "draggableList" &&
          element.reorderParentId &&
          element.reorderGroupId
        ) {
          // Check if the parent is the root elem
          const parentIndex = draggableListIndexMap[element.reorderParentId];
          if (parentIndex > -1) {
            const items = acc[parentIndex]?.items || [];
            items.push(updatedContent);
            acc[parentIndex].items = items;
          } else {
            // Else find the parent item
            const rootElemIndex = draggableListIndexMap[element.reorderGroupId];
            const rootElem = acc[rootElemIndex];
            if (rootElem) {
              const parentElem = rootElem.items?.find(
                elem => elem._id === element.reorderParentId
              ) || rootElem;
              const items = parentElem?.items || [];
              items.push(updatedContent);
              parentElem.items = items;
            }
          }
          return acc;
        }

        acc.push(updatedContent);
        return acc;
      }, []);
    }

    const configurationWithSections = processedConfig(content);
    return (
      <div className="wrapper min-vh-100 mb-5">
        <div
          className="container min-vh-100 py-2 bg-white pt-3 pb-3"
          style={{ padding: "2rem" }}
        >
          {startHTML && <div dangerouslySetInnerHTML={{ __html: startHTML }} />}
          {configurationWithSections
            .map((content, index) =>
              content.hidden ? null : (
                <ContentElement
                  key={`content-element-${content._id}`}
                  session={session}
                  validator={validator}
                  onAddressChanged={onAddressChanged}
                  onUpdating={onUpdating(content.field)}
                  content={content}
                  disableField={this.isDisableField()}
                  survey={survey}
                  processedConfig={configurationWithSections}
                  state={this.state}
                  setState={this.updateState}
                />
              )
            )
            .filter(c => c)}
          {endHTML && <div dangerouslySetInnerHTML={{ __html: endHTML }} />}
          {children}
        </div>
      </div>
    );
  }
}

Page.defaultProps = {
  content: [],
  session: {}
};

export default Page;
