import React, { useCallback, useEffect, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import Page from '../Page/Page';
import { GET_RESPONSE } from '../../apis/survey/schema/response';
import Button from '../Button/Button';
import { numberWithCommas } from '../../utils/projectHelpers';
import './Results.css';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { SEND_EMAIL } from '../../apis/survey/schema/session';
let _ = require('lodash');

function ResultsLoading() {
  return (
    <Page>
      <div className='col-lg-12 col-md-12 col-sm-12 pb-3'>
        <div className='row d-flex justify-content-center'>
          <h3>Loading results</h3>
        </div>
        <div className='row d-flex justify-content-center'>
          <Loading />
        </div>
      </div>
    </Page>
  );
}

function Loading() {
  return (
    <div className='spinner-border' role='status'>
      <span className='sr-only'>Loading...</span>
    </div>
  );
}

const DownloadButton = ({ baseline_id }) => {
  return (
    <a
      href={'http://34.214.180.55/download/' + baseline_id}
      target='_blank'
      rel='noopener noreferrer'
      download
    >
      <Button primary className={'download-button'}>
        DOWNLOAD SIMULATION
      </Button>
    </a>
  );
};

const infoPanelStyle = {
  paddingLeft: '30px',
  paddingRight: '30px',
  'line-height': '175%',
};

const generateReportDownloadLink = (
  userId,
  buildingId,
  templateIds,
  survey,
  session
) => {
  /* There is a duplicate function in survey-api repo. Make sure to keep both functions updated */
  let endDate = moment()
    .subtract(1, 'month')
    .format('YYYY/MM');
  let startDate = moment()
    .subtract(1, 'year')
    .format('YYYY/MM');
  let twelveMonthsTrend = false;
  let formatParam = '';
  if (survey.config?.reportExport) {
    const {
      type,
      startYear,
      startMonth,
      endYear,
      endMonth,
      format,
    } = survey.config?.reportExport;
    if (type === 'LAST_36_MONTHS') {
      startDate = moment()
        .subtract(3, 'year')
        .format('YYYY/MM');
    } else if (type === 'CUSTOM') {
      startDate = `${startYear}/${startMonth}`;
      endDate = `${endYear}/${endMonth}`;
    } else if (type === 'TREND_12_MONTHS') {
      twelveMonthsTrend = true;
      startDate = null;
      endDate = null;
    }
    if (format) {
      formatParam = `&format=${format}`;
    }
  }

  let url = '';
  debugger;
  if (survey.config?.reportExport?.customReport) {
    url = `${
      process.env.REACT_APP_BUILDEE_API
    }/api/reports/v2/custom?buildingId=${buildingId}&templateId=${
      templateIds[0]
    }&surveySessionId=${session._id}`;
  } else {
    url = `${
      process.env.REACT_APP_BUILDEE_API
    }/api/report/user/${userId}/building/${buildingId}/template/${
      templateIds[0]
    }?twelveMonthsTrend=${twelveMonthsTrend}${formatParam}`;
  }
  if (startDate && endDate) {
    url += `&customStartDate=${startDate}&customEndDate=${endDate}`;
  }
  return url;
};

function TableHeader(props) {
  return (
    <tr>
      <th scope='col'>Measure</th>
      {props.showElectricEnergySavingsResults && (
        <th scope='col' className='text-center'>
          Annual Electricity Savings
        </th>
      )}
      {props.showElectricCostSavingsResults && (
        <th scope='col' className='text-center'>
          Annual Electricity Cost Savings
        </th>
      )}
      {props.showNaturalGasResults && props.showNaturalGasEnergySavingsResults && (
        <th scope='col' className='text-center'>
          Annual Natural Gas Savings
        </th>
      )}
      {props.showNaturalGasResults && props.showNaturalGasCostSavingsResults && (
        <th scope='col' className='text-center'>
          Annual Natural Gas Cost Savings
        </th>
      )}
      {props.showCostResults && (
        <th scope='col' className='text-center'>
          Measure Cost
        </th>
      )}
      <th scope='col' className='text-center'>
        Annual Cost Savings
      </th>
      {props.showIncentiveResults && (
        <th scope='col' className='text-center'>
          Estimated Incentive
        </th>
      )}
    </tr>
  );
}

const Results = ({ loading, data = {}, error, survey, session }) => {
  const { response } = data;

  // split simulations project and prescriptive projects
  const simProjects = _.get(response, 'content.projects', []).filter(
    (p) => p.project.analysisType === 'simulation'
  );
  const prescriptiveProjects = _.get(response, 'content.projects', []).filter(
    (p) => p.project.analysisType === 'prescriptive'
  );
  const templateIds = _.get(survey, 'templateIds', []);
  const userId = _.get(survey, 'user');
  const buildingId = _.get(response, 'content.buildingId');
  const showNaturalGasResults =
    _.get(survey, 'config.naturalGasResults', false) || false;
  const showCostResults = _.get(survey, 'config.costResults', false) || false;
  const showElectricCostSavingsResults = _.get(
    survey,
    'config.electricCostSavingsResults',
    false
  );
  const showNaturalGasCostSavingsResults = _.get(
    survey,
    'config.naturalGasCostSavingsResults',
    false
  );
  const showElectricEnergySavingsResults = _.defaultTo(
    _.get(survey, 'config.electricEnergySavingsResults', true),
    true
  );
  const showNaturalGasEnergySavingsResults = _.defaultTo(
    _.get(survey, 'config.naturalGasEnergySavingsResults', true),
    true
  );
  const showIncentiveResults = _.defaultTo(
    _.get(survey, 'config.incentiveResults', true),
    true
  );
  const [sendEmailRequest] = useMutation(SEND_EMAIL);

  const handleDownloadReport = useCallback(() => {
    const reportLink = generateReportDownloadLink(
      userId,
      buildingId,
      templateIds,
      survey,
      session
    );

    // Create a new anchor element
    const link = document.createElement('a');
    link.href = reportLink;
    link.setAttribute('download', 'report.docx');
    link.setAttribute('target', '_blank');

    // Append to the body and click programmatically to trigger download
    document.body.appendChild(link);
    link.click();

    // Remove the link after triggering download
    document.body.removeChild(link);
  }, [userId, buildingId, templateIds, survey, session]);

  const handleSendEmailReport = useCallback(
    (event) => {
      const targetElem = event.target.parentElement;
      const requiredEmailMessage = targetElem.dataset.requireEmail; // if message exists, assert that the `email` field has a value
      const sendTo = targetElem?.getAttribute('sendto');
      if (!sendTo) return;
      const reportEmailFields = {};
      survey.pages?.forEach(({ content = [] }) => {
        content.forEach(({ title, field, includeInEmail = false }) => {
          if (includeInEmail) reportEmailFields[field] = title;
        });
      });
      const subjectTitle =
        targetElem?.getAttribute('subject') || `New ${survey.name} Submission`;
      let emailFieldValue = '';
      const reportEmailFieldValues = session.answers?.reduce(
        (agg, { field, value }) => {
          if (reportEmailFields[field]) {
            agg[field] = value;
          }
          if (field === 'email') emailFieldValue = value;
          return agg;
        },
        {}
      );
      if (!emailFieldValue && requiredEmailMessage) {
        alert(requiredEmailMessage);
        return;
      }
      const surveyLink = `https://survey.buildee.com/survey/${survey.uuid}/session/${session._id}/results/${response._id}`;
      let message = `<ul><li>Survey: ${survey.name}</li><li><a href=${surveyLink} target='_blank'>Survey Link</a></li>`;
      if (response.content?.buildingId && response.content?.buildingName) {
        const buildingLink = `https://app.buildee.com/building/${response.content.buildingId}/overview`;
        message += `<li>Building: <a href=${buildingLink} target='_blank'>${response.content.buildingName}</a></li>`;
      }
      message = Object.keys(reportEmailFields).reduce((agg, field) => {
        agg += `<li>${reportEmailFields[field]}: ${reportEmailFieldValues[field]}</li>`;
        return agg;
      }, message);
      const reportLink = generateReportDownloadLink(
        userId,
        buildingId,
        templateIds,
        survey,
        session
      );
      message += `<li><a href=${reportLink} target='_blank'>Report Link</a></li></ul>`;
      sendEmailRequest({
        variables: { input: { sendTo, subject: subjectTitle, message } },
      });
      alert('Your request has been received!');
    },
    [
      userId,
      buildingId,
      templateIds,
      survey,
      session,
      response,
      sendEmailRequest,
    ]
  );

  useEffect(() => {
    const downloadButtons = document.querySelectorAll('#download-report');
    downloadButtons.forEach((elem) => {
      elem.addEventListener('click', handleDownloadReport);
    });
    return () => {
      downloadButtons.forEach((elem) => {
        elem.removeEventListener('click', handleDownloadReport);
      });
    };
  }, [handleDownloadReport]);

  useEffect(() => {
    const downloadButtons = document.querySelectorAll('#email-report');
    downloadButtons.forEach((elem) => {
      elem.addEventListener('click', handleSendEmailReport);
    });
    return () => {
      downloadButtons.forEach((elem) => {
        elem.removeEventListener('click', handleSendEmailReport);
      });
    };
  }, [handleSendEmailReport]);

  const simResults = (
    <div>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Project</th>
              <th scope='col' className='text-center'>
                Energy Savings Potential Electric (kWh)
              </th>
              <th scope='col' className='text-center'>
                Energy Savings Potential Gas (therms)
              </th>
            </tr>
          </thead>
          <tbody>
            {response &&
              response.content &&
              simProjects &&
              simProjects.map(({ project, projectSimulation }) => (
                <tr key={`content-savings-${projectSimulation._id}`}>
                  <td>{project && project.displayName}</td>
                  <td className='text-center'>
                    {projectSimulation.result.complete === true ? (
                      projectSimulation.result.energy_savings.electric.toFixed(
                        2
                      )
                    ) : (
                      <Loading />
                    )}
                  </td>
                  <td className='text-center'>
                    {projectSimulation.result.complete === true ? (
                      projectSimulation.result.energy_savings.gas.toFixed(2)
                    ) : (
                      <Loading />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <br />
      {simProjects.reduce(function(prev, curr) {
        return prev && curr.projectSimulation.result.complete;
      }, true) ? (
        <React.Fragment>
          {simProjects.length > 0 ? (
            <DownloadButton
              baseline_id={
                simProjects.length > 0
                  ? simProjects[0].projectSimulation.baseline
                  : ''
              }
            />
          ) : (
            console.log('No OpenStudio baseline ID for download found')
          )}
        </React.Fragment>
      ) : (
        <Button primary className={'download-button'}>
          <div className='spinner-border spinner-border-sm' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </Button>
      )}
    </div>
  );

  const prescriptiveResults = (
    <div style={infoPanelStyle}>
      <br />
      <h2>We have identified potential savings!</h2>
      <br />
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <TableHeader
              showNaturalGasResults={showNaturalGasResults}
              showCostResults={showCostResults}
              showElectricCostSavingsResults={showElectricCostSavingsResults}
              showNaturalGasCostSavingsResults={
                showNaturalGasCostSavingsResults
              }
              showElectricEnergySavingsResults={
                showElectricEnergySavingsResults
              }
              showNaturalGasEnergySavingsResults={
                showNaturalGasEnergySavingsResults
              }
              showIncentiveResults={showIncentiveResults}
            />
          </thead>
          <tbody>
            {response &&
              response.content &&
              prescriptiveProjects &&
              prescriptiveProjects.map(({ project }) => {
                // console.log(project.displayName);
                // console.log(project._id);
                // console.log(project.metric);
                return (
                  <tr key={`content-savings-${project.displayName}`}>
                    <td>{project && project.displayName}</td>
                    {showElectricEnergySavingsResults && (
                      <td className='text-center'>
                        {numberWithCommas(
                          _.get(project, 'metric.electricSavings', 0)
                        ) + ' kWh'}
                      </td>
                    )}
                    {showElectricCostSavingsResults && (
                      <td className='text-center'>
                        {'$' +
                          numberWithCommas(
                            Math.ceil(
                              _.get(project, 'metric.annualElectricSavings', 0)
                            )
                          )}
                      </td>
                    )}
                    {showNaturalGasResults &&
                      showNaturalGasEnergySavingsResults && (
                        <td className='text-center'>
                          {numberWithCommas(
                            _.get(project, 'metric.gasSavings', 0)
                          ) + ' therms'}
                        </td>
                      )}
                    {showNaturalGasResults &&
                      showNaturalGasCostSavingsResults && (
                        <td className='text-center'>
                          {'$' +
                            numberWithCommas(
                              Math.ceil(
                                _.get(project, 'metric.annualGasSavings', 0)
                              )
                            )}
                        </td>
                      )}
                    {showCostResults && (
                      <td className='text-center'>
                        {'$' +
                          numberWithCommas(
                            _.get(project, 'metric.projectCost', 0)
                          )}
                      </td>
                    )}
                    <td className='text-center'>
                      {'$' +
                        numberWithCommas(
                          _.get(project, 'metric.annualSavings', 0)
                        )}
                    </td>
                    {showIncentiveResults && (
                      <td className='text-center'>
                        {'$' +
                          numberWithCommas(
                            _.get(project, 'metric.incentive', 0)
                          )}
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <br />
      {(() => {
        if (
          [
            '62bb5bce24c496002a664806',
            '6195450707ba180028aeab36',
            '633b2e0627090400292efb13',
          ].includes(userId)
        ) {
          return (
            <div>
              <p>
                Thank you for participating in the Customer Survey. The attached
                report offers high level recommendations for implementing energy
                efficiency measures at your facility.
              </p>
              <br />
            </div>
          );
        } else if (
          [
            '6195450707ba180028aeab36',
            '6733a5a2f250e4001727dcc9',
            '611ab2836f45dc0028d7cf6c',
          ].includes(userId)
        ) {
          return <div />;
        } else {
          return (
            <div>
              <p>
                Thank you for participating in the Customer Survey. The attached
                report offers high level recommendations for implementing energy
                efficiency measures at your facility. If you'd like additional
                information regarding energy efficiency options at your
                facility, please contact the{' '}
                {[
                  '6195450707ba180028aeab36',
                  '62bb5bce24c496002a664806',
                ].includes(userId)
                  ? 'Utility'
                  : 'PECO'}{' '}
                Ways to Save inbox (
                <a href={'mailto:pecowaystosave@dnv.com'}>
                  {[
                    '6195450707ba180028aeab36',
                    '62bb5bce24c496002a664806',
                  ].includes(userId)
                    ? 'utilitywaystosave@buildee.com'
                    : 'pecowaystosave@dnv.com'}
                </a>
                ) and we'll have an outreach manager contact you. If you'd like
                a list of Trade Allies who can assist you in implementing energy
                efficiency upgrades, please click the link below.
              </p>
              <p>
                <a
                  href={
                    'https://webapps.dnvgl.com/pecotradeally/find-a-contractor/?elqTrackId=fb9d5e3b936e4b0c85baa0967ffab258&elqaid=334&elqat=2'
                  }
                >
                  Find a{' '}
                  {[
                    '6195450707ba180028aeab36',
                    '62bb5bce24c496002a664806',
                  ].includes(userId)
                    ? 'Utility'
                    : 'PECO'}{' '}
                  Trade Ally or Distributor »{' '}
                  {[
                    '6195450707ba180028aeab36',
                    '62bb5bce24c496002a664806',
                  ].includes(userId)
                    ? 'Utility'
                    : 'PECO'}{' '}
                  Provider Hub
                </a>
              </p>
            </div>
          );
        }
      })()}
    </div>
  );

  if (error) {
    console.error(error);
    return <Page>{error}</Page>;
  }
  if (loading) {
    return <ResultsLoading />;
  }
  return (
    <Page>
      {survey?.resultsStartHTML && (
        <div
          dangerouslySetInnerHTML={{ __html: survey.resultsStartHTML }}
        ></div>
      )}
      {simProjects.length > 0 ? simResults : null}
      {prescriptiveProjects.length > 0 &&
      !survey?.config?.hideMeasureResultsTable
        ? prescriptiveResults
        : null}
      {(prescriptiveProjects.length > 0 ||
        (prescriptiveProjects.length === 0 &&
          survey?.config?.requireMeasures !== null &&
          survey?.config?.requireMeasures === false )) &&
        buildingId &&
        templateIds.length > 0 &&
        !survey?.config?.hideDownloadReport &&
        userId && (
          <div className='export'>
            <Button primary id={'download-report'}>
              Download Full Report
            </Button>
          </div>
        )}
      {prescriptiveProjects.length === 0 &&
        (survey?.config?.requireMeasures === null ||
          survey?.config?.requireMeasures === true) &&
        survey && survey.defaultResults && (
        <div dangerouslySetInnerHTML={{ __html: survey.defaultResults }}></div>
      )}
      {survey?.resultsEndHTML && (
        <div dangerouslySetInnerHTML={{ __html: survey.resultsEndHTML }}></div>
      )}
    </Page>
  );
};

const ResultsWithData = ({ match, survey, session }) => {
  const [isFirstPoll, setIsFirstPoll] = useState(true);

  const shouldPoll = survey.projects.some(
    (project) => project.projectType === 'simulation'
  );

  return (
    <Query
      query={GET_RESPONSE}
      variables={{
        response: { _id: match.params.result_id },
        isFirstPoll: isFirstPoll,
      }}
      pollInterval={shouldPoll ? 5000 : 0}
      onCompleted={() => {
        if (isFirstPoll) setIsFirstPoll(false);
      }}
    >
      {({ ...result }) => {
        return (
          <Results
            {...result}
            match={match}
            survey={survey}
            session={session}
          />
        );
      }}
    </Query>
  );
};

export default ResultsWithData;
