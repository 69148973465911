import RenderContentType from "./RenderContentType";

function evaluateCondition(condition, formValues) {
  if (condition.and) {
    for (let c of condition.and) {
      if (!evaluateCondition(c, formValues)) return false;
    }
    return true;
  }
  if (condition.or) {
    for (let c of condition.or) {
      if (evaluateCondition(c, formValues)) return true;
    }
    return false;
  }

  let fieldValue = formValues[condition.field];
  let gt, gte, lt, lte, eq, includes, contains, neq, notNull, notIn;
  gt = gte = lt = lte = eq = includes = contains = neq = notNull = notIn = true;
  if (condition.gt || condition.gt === 0 || condition.gt === "")
    gt = fieldValue > condition.gt;
  if (condition.gte || condition.gte === 0 || condition.gte === "")
    gte = fieldValue >= condition.gte;
  if (condition.lt || condition.lt === 0 || condition.lt === "")
    lt = fieldValue < condition.lt;
  if (condition.lte || condition.lte === 0 || condition.lte === "")
    lte = fieldValue <= condition.lte;
  if (condition.eq || condition.eq === 0 || condition.eq === "")
    eq = fieldValue === condition.eq;
  if (condition.neq || condition.neq === 0 || condition.neq === "")
    neq = fieldValue !== condition.neq;
  if (condition.notNull)
    notNull = fieldValue !== undefined && fieldValue !== null;
  if (condition.in) {
    includes = condition.in.indexOf(fieldValue) >= 0;
  }
  if (condition.notIn) {
    notIn = condition.notIn.indexOf(fieldValue) < 0;
  }
  if (condition.contains) {
    if (!fieldValue) {
      contains = false;
    } else {
      contains = fieldValue.indexOf(condition.contains) >= 0;
    }
  }

  return (
    gt &&
    gte &&
    lt &&
    lte &&
    eq &&
    includes &&
    contains &&
    neq &&
    notNull &&
    notIn
  );
}

function evaluateConditions(conditions, formValues, condition) {
  if (conditions === null && condition != null) {
    return evaluateCondition(condition, formValues);
  }
  if (conditions.length === 0) return true;

  let conditionAccepted = false;
  for (let condition of conditions) {
    let operation = condition.operation || "OR";
    let thisConditionAccepted = evaluateCondition(condition, formValues);
    if (operation === "OR")
      conditionAccepted = conditionAccepted || thisConditionAccepted;
    if (operation === "AND")
      conditionAccepted = conditionAccepted && thisConditionAccepted;
  }
  return conditionAccepted;
}

export function getConditionalState(conditionalStates, answers) {
  let state = "SHOW";
  if (!conditionalStates || !answers) return state;
  let formValues = {};
  for (let answer of answers) formValues[answer.field] = answer.value;

  for (let conditionalState of conditionalStates) {
    let conditionResult = evaluateConditions(
      conditionalState.conditions,
      formValues,
      conditionalState.condition
    );
    if (conditionResult) return conditionalState.state;
  }
  return state;
}


function ContentElement({
  onAddressChanged,
  session = {},
  validator,
  disableField,
  onUpdating,
  survey,
  processedConfig,
  state,
  setState,
  content,
}) {
  /* Evaluate Conditional States */
  content.state = getConditionalState(
    content.conditionalState,
    session.answers
  );

  return (
    <div
      className="row"
      style={{ display: content.state === "HIDE" ? "none" : "block" }}
    >
      {content.startHTML && (
        <div dangerouslySetInnerHTML={{ __html: content.startHTML }} />
      )}
      <RenderContentType
        key={`content-element-${content._id}`}
        session={session}
        validator={validator}
        onAddressChanged={onAddressChanged}
        onUpdating={onUpdating}
        content={content}
        disableField={disableField}
        survey={survey}
        processedConfig={processedConfig}
        state={state}
        setState={setState}
      />
      {content.endHTML && (
        <div dangerouslySetInnerHTML={{ __html: content.endHTML }} />
      )}
    </div>
  );
}

export default ContentElement;