import React, { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import DraggableListItem from "../DraggableListItem";
import {
  ADD_SESSION_ANSWER,
  UPDATE_SESSION_ANSWER,
  updateAfterAddSessionAnswer,
  updateAfterUpdateSessionAnswer
} from "../../apis/survey/schema/session";
import { generateOptimisticResponse } from "../../utils/question";
import { Mutation } from "@apollo/client/react/components";
import { ObjectId } from "bson";

const getDefaultOrder = items => {
  return items.map(item => item._id);
};

const getOrderedListItems = (listItems, order) => {
  const mappedListItems = listItems.reduce((agg, item) => {
    agg[item._id] = item;
    return agg;
  }, {});
  return order.map(itemId => mappedListItems[itemId]);
};

const DraggableList = ({
  answer,
  content,
  listItems,
  renderListItem,
  session
}) => {
  const [order, setOrder] = useState(
    answer?.order ? answer.order : getDefaultOrder(listItems)
  );
  const [, drop] = useDrop(() => ({ accept: ItemTypes.LIST_ITEM }));

  const moveListItem = useCallback(
    executeMutation => async (itemId, fromIndex, toIndex) => {
      const newOrder = [...order];
      const tempItem = order[fromIndex];
      newOrder.splice(fromIndex, 1);
      newOrder.splice(toIndex, 0, tempItem);
      setOrder(newOrder);
      try {
        if (answer?._id) {
          const optimisticResponse = generateOptimisticResponse(
            answer,
            session,
            "",
            "updateAnswer",
            newOrder
          );
          //Not new, send update
          await executeMutation({
            optimisticResponse,
            variables: {
              session: { _id: session._id },
              answer: {
                _id: answer._id,
                order: newOrder
              }
            }
          });
        } else {
          const newAnswer = {
            order: newOrder,
            field: content.field,
            question: content._id,
            _id: new ObjectId().toString(),
            value: ""
          };
          const optimisticResponse = generateOptimisticResponse(
            newAnswer,
            session,
            "",
            "addAnswer",
            newOrder
          );
          //Is new, send create
          await executeMutation({
            optimisticResponse,
            variables: {
              session: { _id: session._id },
              answer: newAnswer
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [order, content]
  );

  let mutation,
    updateAfterMutation,
    updateVariables = { session: { _id: session._id } };

  if (answer?._id) {
    //Not new, send update
    mutation = UPDATE_SESSION_ANSWER;
    updateAfterMutation = (...args) =>
      updateAfterUpdateSessionAnswer(...args, updateVariables);
  } else {
    //Is new, send create
    mutation = ADD_SESSION_ANSWER;
    updateAfterMutation = (...args) =>
      updateAfterAddSessionAnswer(...args, updateVariables);
  }

  return (
    <Mutation mutation={mutation} update={updateAfterMutation}>
      {(executeMutation, { data, loading }) => {
        return (
          <div ref={drop}>
            {getOrderedListItems(listItems, order).map((item, index) => (
              <DraggableListItem
                key={`draggable-list-item-${item._id}`}
                renderListItem={renderListItem}
                itemIndex={index}
                moveItem={moveListItem(executeMutation)}
                item={item}
              />
            ))}
          </div>
        );
      }}
    </Mutation>
  );
};

export default DraggableList;
