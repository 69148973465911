import React, { useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";

import client, { offlineLink } from "../../apis/survey/graphqlClient";
import "./App.css";
import NetworkIndicator from "../NetworkIndicator";
import DataSyncer from "../DataSyncer";
import "bootstrap/dist/css/bootstrap.min.css";
import Survey from "../Survey/Survey";
import useNetwork from "../../utils/useNetwork";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const handleWindowUnload = event => {
  if (offlineLink.queue.size > 0) {
    event.preventDefault();
    event.returnValue = "You have unsaved changes";
  }
};

function App() {
  const networkState = useNetwork();
  useEffect(() => {
    window.addEventListener("beforeunload", handleWindowUnload);
    return () => {
      window.removeEventListener("beforeunload", handleWindowUnload);
    };
  }, []);
  return (
    <ApolloProvider client={client}>
      <NetworkIndicator state={networkState} />
      <DataSyncer offlineLink={offlineLink} isOnline={networkState.online} />
      <DndProvider backend={HTML5Backend}>
        <Router>
          <Route
            exact
            path={[
              "/survey/:survey_id",
              "/survey/:survey_id/session/:session_id",
              "/survey/:survey_id/session/:session_id/results/:result_id"
            ]}
            component={Survey}
          />
        </Router>
      </DndProvider>
    </ApolloProvider>
  );
}

export default App;
