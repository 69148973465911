import gql from "graphql-tag";

const session = `
  _id
  survey
  user
  answers {
    _id
    field
    value
    question
    validation {
      isValid
      message
    }
    order
  }
  status
  response
  created
`;

const endUse = `
  endUse
  answers {
    _id
    field
    value
    question
  }
`;

export const GET_SESSION = gql`
  query session($session: SearchSessionInput!) {
    session(session: $session) {
      ${session}
    }
  }
`;

export const CREATE_SESSION = gql`
  mutation createSession($session: CreateSessionInput!) {
    createSession(session: $session) {
      ${session}
    }
  }
`;

export const UPDATE_SESSION = gql`
  mutation updateSession($session: UpdateSessionInput!) {
    updateSession(session: $session) {
      ${session}
    }
  }
`;

export const GET_EUB = gql`
  query getEub($session: UpdateEubInput!) {
    getEub(session: $session) {
      ${endUse}
    }
  }
`;

export const UPDATE_EUB = gql`
  mutation updateEub($session: UpdateEubInput!, $endUseConfiguration: JSON) {
    updateEub(session: $session, endUseConfiguration: $endUseConfiguration) {
      ${endUse}
    }
  }
`;

export const ADD_SESSION_ANSWER = gql`
  mutation addAnswer($session: SearchSessionInput!, $answer: AddAnswerInput) {
    addAnswer(session: $session, answer: $answer) {
      ${session}
    }
  }
`;

export const REMOVE_SESSION_ANSWER = gql`
  mutation removeAnswer($session: SearchSessionInput!, $answer: RemoveAnswerInput) {
    removeAnswer(session: $session, answer: $answer) {
      ${session}
    }
  }
`;

export const UPDATE_SESSION_ANSWER = gql`
  mutation updateAnswer($session: SearchSessionInput!, $answer: UpdateAnswerInput) {
    updateAnswer(session: $session, answer: $answer) {
      ${session}
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation sendEmail($input: SendEmailInput!) {
    sendEmail(input: $input) {
      status
      message
    }
  }
`;

export const updateAfterCreateSession = (
  cache,
  { data: { createSession: session } },
  variables
) => {
  cache.writeQuery({
    query: GET_SESSION,
    variables: { _id: session._id },
    data: { session }
  });
};

export const updateAfterUpdateSession = (
  cache,
  { data: { updateSession: session } },
  variables
) => {
  cache.writeQuery({
    query: GET_SESSION,
    variables: { _id: session._id },
    data: { session }
  });
};

export const updateAfterUpdateEub = (
  cache,
  { data: { updateEub: session } },
  variables
) => {
  cache.writeQuery({
    query: GET_SESSION,
    variables: { _id: session._id },
    data: { session }
  });
};

export const updateAfterAddSessionAnswer = (
  cache,
  { data: { addAnswer: session } },
  variables
) => {
  cache.writeQuery({
    query: GET_SESSION,
    variables: { _id: session._id },
    data: { session }
  });
};

export const updateAfterRemoveSessionAnswer = (
  cache,
  { data: { removeAnswer: session } },
  variables
) => {
  cache.writeQuery({
    query: GET_SESSION,
    variables: { _id: session._id },
    data: { session }
  });
};

export const updateAfterUpdateSessionAnswer = (
  cache,
  { data: { updateAnswer: session } },
  variables
) => {
  cache.writeQuery({
    query: GET_SESSION,
    variables: { _id: session._id },
    data: { session }
  });
};
