import gql from "graphql-tag";

export const GET_SURVEY = gql`
  query survey($survey: SearchSurveyInput!) {
    survey(survey: $survey) {
      _id
      uuid
      name
      building
      organization
      user
      analysis
      config {
        createBuilding
        naturalGasResults
        costResults
        electricCostSavingsResults
        naturalGasCostSavingsResults
        electricEnergySavingsResults
        naturalGasEnergySavingsResults
        incentiveResults
        startSession
        overrideBuildingData
        reportExport {
          type
          startYear
          startMonth
          endYear
          endMonth
          format
          customReport
        }
        allowResubmit
        eubType
        hideMeasureResultsTable
        hideDownloadReport
        hideTitle
        coverPageOnly
        requireMeasures
      }
      theme {
        primaryColor
        secondaryColor
        logoSrc
        faviconSrc
      }
      defaultResults
      resultsStartHTML
      resultsEndHTML
      templateIds
      pages {
        _id
        title
        startHTML
        endHTML
        content {
          _id
          type
          title
          body
          startHTML
          endHTML
          options {
            value
            displayName
          }
          default
          format
          field
          hidden
          initialValue
          conditionalOptions {
            conditions {
              operation
              field
              eq
              gt
              gte
              lt
              lte
              neq
              in
              contains
              notNull
              notIn
            }
            condition {
              and {
                field
                eq
                gt
                gte
                lt
                lte
                neq
                in
                contains
                notNull
                notIn
                and {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
                or {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
              }
              or {
                field
                eq
                gt
                gte
                lt
                lte
                neq
                in
                contains
                notNull
                notIn
                and {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
                or {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
              }
            }
            options {
              value
              displayName
            }
          }
          conditionalState {
            conditions {
              operation
              field
              eq
              gt
              gte
              lt
              lte
              neq
              in
              contains
              notNull
              notIn
            }
            condition {
              and {
                field
                eq
                gt
                gte
                lt
                lte
                neq
                in
                contains
                notNull
                notIn
                and {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
                or {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
              }
              or {
                field
                eq
                gt
                gte
                lt
                lte
                neq
                in
                contains
                notNull
                notIn
                and {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
                or {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                }
              }
            }
            state
          }
          state
          infoField
          required
          includeInEmail
          toggle
          toggleType
          columns {
            order
            title
            width
            conditionalState {
              conditions {
                operation
                field
                eq
                gt
                gte
                lt
                lte
                neq
                in
                contains
                notNull
                notIn
              }
              condition {
                and {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                  and {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                  or {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                }
                or {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                  and {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                  or {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                }
              }
              state
            }
          }
          rows {
            order
            conditionalState {
              conditions {
                operation
                field
                eq
                gt
                gte
                lt
                lte
                neq
                in
                contains
                notNull
                notIn
              }
              condition {
                and {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                  and {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                  or {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                }
                or {
                  field
                  eq
                  gt
                  gte
                  lt
                  lte
                  neq
                  in
                  contains
                  notNull
                  notIn
                  and {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                  or {
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                }
              }
              state
            }
            values {
              column
              content {
                _id
                type
                title
                body
                startHTML
                endHTML
                options {
                  value
                  displayName
                }
                default
                format
                field
                hidden
                initialValue
                conditionalOptions {
                  conditions {
                    operation
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                  condition {
                    and {
                      field
                      eq
                      gt
                      gte
                      lt
                      lte
                      neq
                      in
                      contains
                      notNull
                      notIn
                      and {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                      or {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                    }
                    or {
                      field
                      eq
                      gt
                      gte
                      lt
                      lte
                      neq
                      in
                      contains
                      notNull
                      notIn
                      and {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                      or {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                    }
                  }
                  options {
                    value
                    displayName
                  }
                }
                conditionalState {
                  conditions {
                    operation
                    field
                    eq
                    gt
                    gte
                    lt
                    lte
                    neq
                    in
                    contains
                    notNull
                    notIn
                  }
                  condition {
                    and {
                      field
                      eq
                      gt
                      gte
                      lt
                      lte
                      neq
                      in
                      contains
                      notNull
                      notIn
                      and {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                      or {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                    }
                    or {
                      field
                      eq
                      gt
                      gte
                      lt
                      lte
                      neq
                      in
                      contains
                      notNull
                      notIn
                      and {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                      or {
                        field
                        eq
                        gt
                        gte
                        lt
                        lte
                        neq
                        in
                        contains
                        notNull
                        notIn
                      }
                    }
                  }
                  state
                }
                state
                infoField
                required
                includeInEmail
                toggle
                toggleType
              }
            }
          }
          reorderParentId
          reorderGroupId
        }
      }
      projects {
        projectId
        projectType
      }
      created
      config {
        pageNavigationType
      }
    }
  }
`;
