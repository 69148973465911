import React from "react";
import { Formik, Field } from "formik";
import { ObjectId } from "bson";
import { Mutation } from "@apollo/client/react/components";
import {
  ADD_SESSION_ANSWER,
  UPDATE_SESSION_ANSWER,
  updateAfterAddSessionAnswer,
  updateAfterUpdateSessionAnswer,
} from "../../apis/survey/schema/session";
import "./SelectQuestion.css";
import ToolTip from "../ToolTip";
import { generateOptimisticResponse } from "../../utils/question";

class SelectQuestion extends React.Component {
  handleSubmit(executeMutation, values) {
    const {
      content: { _id, field },
      answer,
      session,
    } = this.props;
    if (answer._id) {
      const optimisticResponse = generateOptimisticResponse(
        answer,
        session,
        values[field],
        "updateAnswer"
      );
      //Not new, send update
      return executeMutation({
        optimisticResponse,
        variables: {
          session: { _id: session._id },
          answer: {
            _id: answer._id,
            value: values[field],
          },
        },
      });
    } else {
      const newAnswer = {
        value: values[field],
        field,
        question: _id,
        _id: new ObjectId().toString(),
      };
      const optimisticResponse = generateOptimisticResponse(
        newAnswer,
        session,
        values[field],
        "addAnswer"
      );
      //Is new, send create
      return executeMutation({
        optimisticResponse,
        variables: {
          session: { _id: session._id },
          answer: newAnswer,
        },
      });
    }
  }

  evaluateCondition = (condition, formValues) => {
    if (condition.and) {
      for (let c of condition.and) {
        if (!this.evaluateCondition(c, formValues)) return false;
      }
      return true;
    }
    if (condition.or) {
      for (let c of condition.or) {
        if (this.evaluateCondition(c, formValues)) return true;
      }
      return false;
    }

    let fieldValue = formValues[condition.field];
    let gt, gte, lt, lte, eq, includes, contains, neq, notNull, notIn;
    gt = gte = lt = lte = eq = includes = contains = neq = notNull = notIn = true;
    if (condition.gt || condition.gt === 0 || condition.gt === "")
      gt = fieldValue > condition.gt;
    if (condition.gte || condition.gte === 0 || condition.gte === "")
      gte = fieldValue >= condition.gte;
    if (condition.lt || condition.lt === 0 || condition.lt === "")
      lt = fieldValue < condition.lt;
    if (condition.lte || condition.lte === 0 || condition.lte === "")
      lte = fieldValue <= condition.lte;
    if (condition.eq || condition.eq === 0 || condition.eq === "")
      eq = fieldValue === condition.eq;
    if (condition.neq || condition.neq === 0 || condition.neq === "")
      neq = fieldValue !== condition.neq;
    if (condition.notNull)
      notNull = fieldValue !== undefined && fieldValue !== null;
    if (condition.in) {
      includes = condition.in.indexOf(fieldValue) >= 0;
    }
    if (condition.notIn) {
      notIn = condition.notIn.indexOf(fieldValue) < 0;
    }
    if (condition.contains) {
      if (!fieldValue) {
        contains = false;
      } else {
        contains = fieldValue.indexOf(condition.contains) >= 0;
      }
    }

    return (
      gt &&
      gte &&
      lt &&
      lte &&
      eq &&
      includes &&
      contains &&
      neq &&
      notNull &&
      notIn
    );
  };

  evaluateConditions = (conditions, formValues, condition) => {
    if (conditions === null && condition != null) {
      return this.evaluateCondition(condition, formValues);
    }
    if (conditions.length === 0) return true;

    let conditionAccepted = false;
    for (let condition of conditions) {
      let operation = condition.operation || "OR";
      let thisConditionAccepted = this.evaluateCondition(condition, formValues);
      if (operation === "OR")
        conditionAccepted = conditionAccepted || thisConditionAccepted;
      if (operation === "AND")
        conditionAccepted = conditionAccepted && thisConditionAccepted;
    }
    return conditionAccepted;
  };

  getConditionalOptions = (conditionalOptions, answers) => {
    let options = [];
    if (!conditionalOptions || !answers) return options;
    let formValues = {};
    for (let answer of answers) formValues[answer.field] = answer.value;

    for (let conditionalOption of conditionalOptions) {
      let conditionResult = this.evaluateConditions(
        conditionalOption.conditions,
        formValues,
        conditionalOption.condition
      );
      if (conditionResult) options = options.concat(conditionalOption.options);
    }
    return options;
  };

  render() {
    const {
      content,
      answer,
      session,
      validator,
      disableField,
      isVisible,
    } = this.props;

    let {
      title,
      body,
      field,
      options,
      conditionalOptions,
      state,
      infoField,
      required,
      parentTextId,
    } = content;

    let mutation,
      updateAfterMutation,
      initialValues,
      updateVariables = { session: { _id: session._id } };

    if (answer._id) {
      //Not new, send update
      mutation = UPDATE_SESSION_ANSWER;
      updateAfterMutation = (...args) =>
        updateAfterUpdateSessionAnswer(...args, updateVariables);
      initialValues = { [field]: answer.value };
    } else {
      //Is new, send create
      mutation = ADD_SESSION_ANSWER;
      updateAfterMutation = (...args) =>
        updateAfterAddSessionAnswer(...args, updateVariables);
      initialValues = { [`${field}`]: "" };
    }

    let extraOptions = this.getConditionalOptions(
      conditionalOptions,
      session.answers
    );
    options = options.concat(extraOptions);

    let validationOptions = "";
    if ((title.trim().startsWith("*") || required) && state !== "HIDE") {
      validationOptions = "required";
    }

    return (
      <Mutation mutation={mutation} update={updateAfterMutation}>
        {(executeMutation, { data, loading }) => {
          return (
            <Formik initialValues={initialValues} enableReinitialize={true}>
              {({ errors, values, setFieldValue }) => (
                <form
                  className={`form-group ${
                    state === "HIDE" || !isVisible ? "d-none" : "d-block"
                  }`}
                  style={{ marginLeft: parentTextId ? "35px" : "0" }}
                >
                  {title && (
                    <label htmlFor={field} className="form-select-label">
                      {title}
                      {infoField && (
                        <ToolTip
                          content={<span>{infoField}</span>}
                          direction="right"
                        >
                          <i className="bi bi-info-circle icon"></i>
                        </ToolTip>
                      )}
                    </label>
                  )}
                  <Field
                    name={field}
                    className={`form-control form-control-select ${
                      !title ? "mt-0" : ""
                    }`}
                    component="select"
                    placeholder={body}
                    onChange={({ target }) =>
                      this.handleSubmit(executeMutation, {
                        [field]: target.value,
                      })
                    }
                    disabled={disableField}
                    onBlur={() => {
                      validator.showMessageFor(field);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Delete") {
                        setFieldValue(field, "");
                      }
                    }}
                  >
                    {[{ value: "", displayName: "Select" }]
                      .concat(options)
                      .map(({ value, displayName, disabled = false }) => (
                        <option key={value} value={value} disabled={disabled}>
                          {displayName}
                        </option>
                      ))}
                  </Field>
                  {validationOptions !== "" &&
                    validator.message(field, values[field], validationOptions)}
                  {values[field] &&
                    answer.validation &&
                    !answer.validation.isValid && (
                      <div className="srv-validation-message">
                        {answer.validation.message}
                      </div>
                    )}
                </form>
              )}
            </Formik>
          );
        }}
      </Mutation>
    );
  }
}

SelectQuestion.defaultProps = {
  content: {},
  session: {},
  answer: {},
};

export default SelectQuestion;
